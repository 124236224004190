import { Schema, stubModel } from './schema';
import { normalizeSchema } from './normalizeSchema';
//
import { user } from './resources/user';
import { organization } from './resources/organization';
import { account } from './resources/account';
import { workspace } from './resources/workspace';
import { role } from './resources/role';
import { library } from './resources/library';
import { project } from './resources/project';
import { projectMember } from './resources/project-member';
import { share } from './resources/share';
import { contentTreeNode } from './resources/content-tree-node';
import { folder } from './resources/folder';
import { asset } from './resources/asset';
import { metadataCategory } from './resources/metadata-category';
import { metadataValues } from './resources/metadata-value';
import { metadataProperty } from './resources/metadata-property';
import { metadataField } from './resources/metadata-field';
import { workspacePreference } from './resources/workspace-preference';
import { activity } from './resources/activities';
import { contract } from './resources/contract';
import { contractType } from './resources/contractType.ts';
import { download } from './resources/download';
import { privilege } from './resources/privilege';
import { featureFlag } from './resources/featureFlag';
import { tag } from './resources/tag';
import { tagCategory } from './resources/tag-category';
import { exportTargetType } from './resources/export-target-type';
import { exportTarget } from './resources/export-target';
import { exportBatch } from './resources/export-batch';
import { projectTemplate } from './resources/project-template';
import { invitation } from './resources/invitation';
import { contractKeyGroup } from './resources/contract-key-group';
import { contractValue } from './resources/contract-value';
import { contractForm } from './resources/contract-form';
import { changeRequest } from './resources/change-request';
import { searchResult } from './resources/search-result';
import { meiliSearchResult } from './resources/meili-search-result';
import { forgotPassword } from './resources/forgot-password';
import { recipient } from './resources/recipient';
import { membership } from './resources/membership';
import { properties } from './resources/properties';
import { oauth2 } from './resources/oauth2';
import { exportTargetDefault } from './resources/export-target-default';
import { fileUpload } from './resources/file-upload';
import { team } from './resources/team';
import { projectTeamMembership } from './resources/project-team-membership';
import { validator } from './resources/validator';
import { validation } from './resources/validation';
import { oauth2Provider } from './resources/oauth2-provider';
import { comment } from './resources/comment';
import { approval } from './resources/approval';
import { renderPreset } from './resources/render-preset';
import { renderBatch } from './resources/render-batch';
import { annotation } from './resources/annotation';
import { favorite } from './resources/favorite';
import { url } from './resources/url';
import { snapshot } from './resources/snapshot';

export const schema = new Schema(
  normalizeSchema({
    version: [0, 26, 0],
    resources: [
      user,
      organization,
      account,
      annotation,
      workspace,
      role,
      library,
      project,
      projectMember,
      share,
      contentTreeNode,
      folder,
      asset,
      metadataCategory,
      metadataValues,
      workspacePreference,
      activity,
      contract,
      contractType,
      download,
      renderPreset,
      renderBatch,
      privilege,
      featureFlag,
      tag,
      tagCategory,
      exportTargetType,
      exportTarget,
      exportBatch,
      exportTargetDefault,
      snapshot,
      invitation,
      projectTemplate,
      contractKeyGroup,
      contractValue,
      contractForm,
      metadataProperty,
      metadataField,
      changeRequest,
      searchResult,
      meiliSearchResult,
      forgotPassword,
      recipient,
      membership,
      properties,
      oauth2,
      oauth2Provider,
      fileUpload,
      team,
      projectTeamMembership,
      validator,
      validation,
      comment,
      approval,
      stubModel('content-tree', 'content-trees'),
      favorite,
      url
    ]
  })
);

export * from './schema';
