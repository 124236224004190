import React from 'react';

export const useGlobalSubmit = (onClick: Function, disabled: boolean) => {
  const handleKeypress = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Enter' && !disabled) {
        ev.preventDefault();
        onClick(ev);
      }
    },
    [onClick, disabled]
  );

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeypress, true);
    return () => window.removeEventListener('keyup', handleKeypress, true);
  }, [handleKeypress]);
};
