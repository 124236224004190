import { Relation } from './state';
import { ns } from './config';
import {
  RelationshipIdentifier,
  ResourceIdentifier,
  ResourceType
} from '@cube3/common/model/resource-types';
import { Params } from '../../../wrapped-cube-client/params';

// Actions
const RETRIEVE_RESOURCE_EDGES = `${ns}/RETRIEVE_RESOURCE_EDGES` as const;
const RETRIEVE_MORE_RESOURCE_EDGES =
  `${ns}/RETRIEVE_MORE_RESOURCE_EDGES` as const;
const RETRIEVE_ALL_RESOURCE_EDGES =
  `${ns}/RETRIEVE_ALL_RESOURCE_EDGES` as const;
const RECEIVE_RESOURCE_EDGES = `${ns}/RECEIVE_RESOURCE_EDGES` as const;
const RECEIVE_RELATIONS = `${ns}/RECEIVE_RELATIONS` as const;
const RECEIVE_PAGINATION_SIZE = `${ns}/RECEIVE_PAGINATION_SIZE` as const;
const SET_RELATION_STALE = `${ns}/SET_RELATION_STALE` as const;
const ACTION_FAILED = `${ns}/ACTION_FAILED` as const;
const MUTATE_RESOURCE_EDGE = `${ns}/MUTATE_RESOURCE_EDGE` as const;
const MUTATE_MANY_RESOURCE_EDGES = `${ns}/MUTATE_MANY_RESOURCE_EDGES` as const;
const INVALIDATE_EDGE_CACHE = `${ns}/INVALIDATE_EDGE_CACHE` as const;
const UGLY_MOVE_ITEMS = `${ns}/UGLY_MOVE_ITEMS` as const;
const UGLY_COPY_ITEMS = `${ns}/UGLY_COPY_ITEMS` as const;
//
const MUTATION_ADD = `${ns}/mutations/MUTATION_ADD` as const;
const MUTATION_REMOVE = `${ns}/mutations/MUTATION_REMOVE` as const;
const MUTATION_REPLACE = `${ns}/mutations/MUTATION_REPLACE` as const;

export const actions = {
  RETRIEVE_RESOURCE_EDGES,
  RETRIEVE_MORE_RESOURCE_EDGES,
  RETRIEVE_ALL_RESOURCE_EDGES,
  RECEIVE_RESOURCE_EDGES,
  RECEIVE_RELATIONS,
  RECEIVE_PAGINATION_SIZE,
  SET_RELATION_STALE,
  ACTION_FAILED,
  MUTATE_RESOURCE_EDGE,
  MUTATE_MANY_RESOURCE_EDGES,
  INVALIDATE_EDGE_CACHE,
  UGLY_MOVE_ITEMS,
  UGLY_COPY_ITEMS
} as const;

export const mutations = {
  MUTATION_ADD,
  MUTATION_REMOVE,
  MUTATION_REPLACE
} as const;

export const allowedTypes = Object.keys(actions).map((k) => actions[k]);

// ActionCreators
const retrieveResourceEdges = (
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  params?
) => ({
  type: actions.RETRIEVE_RESOURCE_EDGES,
  payload: null, // only used in middleware
  meta: {
    apiClient: {
      resourceId,
      resourceType,
      edgeLabel,
      edgeType,
      params
    }
  }
});

// ActionCreators
const retrieveMoreResourceEdges = (
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  params?
) => {
  return {
    type: actions.RETRIEVE_MORE_RESOURCE_EDGES,
    payload: null, // only used in middleware
    meta: {
      apiClient: {
        resourceId,
        resourceType,
        edgeLabel,
        edgeType,
        params
      }
    }
  };
};

const retrieveAllResourceEdges = (
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  params?
) => {
  return {
    type: actions.RETRIEVE_ALL_RESOURCE_EDGES,
    payload: null, // only used in middleware
    meta: {
      apiClient: {
        resourceId,
        resourceType,
        edgeLabel,
        edgeType,
        params
      }
    }
  };
};

const receiveResourceEdges = (
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  edges,
  options = {},
  params = undefined
) => {
  return {
    type: actions.RECEIVE_RESOURCE_EDGES,
    payload: { resourceId, resourceType, edgeLabel, edgeType, edges, params },
    meta: { options: { ...options } }
  };
};

const receiveRelations = (relations: Relation[], options = {}) => {
  const action = {
    type: actions.RECEIVE_RELATIONS,
    payload: relations.map((relation) => {
      const { from, to, name, label, pageNumber } = relation;
      return {
        edgeLabel: label || name,
        pageNumber: pageNumber,
        resourceId: from.id,
        resourceType: from.type,
        edgeType: to.type,
        edgeId: to.id
      };
    }),
    meta: { options: { ...options } }
  };
  return action;
};

const receivePagianationSize = (
  { resourceType, resourceId, edgeLabel, params },
  totalItems,
  options = {}
) => {
  return {
    type: actions.RECEIVE_PAGINATION_SIZE,
    payload: {
      edgeLabel,
      resourceId,
      resourceType,
      params,
      totalItems
    },
    meta: { options: { ...options } }
  };
};

const setRelationStale = (resourceId, resourceType, edgeLabel, params) => {
  return {
    type: actions.SET_RELATION_STALE,
    payload: {
      resourceId,
      resourceType,
      edgeLabel,
      params
    }
  };
};

const actionFailed = (action) => ({
  type: actions.ACTION_FAILED,
  payload: action,
  meta: {}
});

/**
 * Edge mutations
 *
 * These mutations are usually applied to a JSONApi relationships endpoint.
 * Adding an edge doesn't create a resource, but links existing resources together
 * Removing an edge doesn't delete the resource, but unlinks it from the parent
 */

const mutateResourceEdge = (
  mutationType,
  resourceType,
  resourceId,
  edgeType,
  edgeLabel,
  edgeResource,
  options = {},
  invalidatesCache = undefined
) => {
  const action = {
    type: actions.MUTATE_RESOURCE_EDGE,
    payload: null,
    meta: {
      apiClient: {
        mutationType,
        resourceId,
        resourceType,
        edgeLabel,
        edgeType,
        edgeResource,
        options,
        invalidatesCache
      }
    }
  };
  return action;
};

export interface EdgeMutation {
  /** resource that owns the relationship */
  ancestor: { type: ResourceType; id: string };
  /** name of the relationship */
  relationshipLabel: string;
  /** type of mutation */
  mutationType:
    | typeof mutations.MUTATION_ADD
    | typeof mutations.MUTATION_REMOVE
    | typeof mutations.MUTATION_REPLACE;
  /** resource to add or remove from relationship */
  resource: ResourceIdentifier;
  invalidatesCache?: boolean | ResourceIdentifier[];
}

export interface MutateManyResourceEdgesOptions {
  batch?: boolean;
  JSONApi?: boolean;
  optimize?: boolean;
}

interface MutateManyResourceEdges {
  /** list of mutations to apply */
  mutations: EdgeMutation[];
  /** additional options (tbd) */
  options?: MutateManyResourceEdgesOptions;
  invalidatesCache?:
    | boolean
    | Array<ResourceIdentifier | RelationshipIdentifier>;
  params?: Params;
}

const mutateManyResourceEdges = ({
  mutations = [],
  options = {},
  invalidatesCache = [],
  params
}: MutateManyResourceEdges) => {
  return {
    type: actions.MUTATE_MANY_RESOURCE_EDGES,
    payload: null,
    meta: {
      apiClient: {
        mutations: mutations,
        options, //{data:{}, files?:[], temporaryId?:string}
        invalidatesCache,
        params
      }
    }
  };
};

const addResourceEdge = (
  resourceType,
  resourceId,
  edgeType,
  edgeLabel,
  edgeResource,
  options = undefined
) => {
  return mutateResourceEdge(
    mutations.MUTATION_ADD,
    resourceType,
    resourceId,
    edgeType,
    edgeLabel,
    edgeResource,
    options
  );
};

const removeResourceEdge = (
  resourceType,
  resourceId,
  edgeType,
  edgeLabel,
  edgeResource,
  options = undefined
) => {
  return mutateResourceEdge(
    mutations.MUTATION_REMOVE,
    resourceType,
    resourceId,
    edgeType,
    edgeLabel,
    edgeResource,
    options
  );
};

/** PATCH request */
const updateResourceEdge = ({
  resourceType,
  resourceId,
  edgeType,
  edgeLabel,
  edgeResource,
  options = undefined,
  invalidatesCache = undefined
}) => {
  return mutateResourceEdge(
    mutations.MUTATION_REPLACE,
    resourceType,
    resourceId,
    edgeType,
    edgeLabel,
    edgeResource,
    options,
    invalidatesCache
  );
};

const uglyMoveItems = (items, targetFolder) => ({
  type: actions.UGLY_MOVE_ITEMS,
  payload: {
    items,
    targetFolder
  },
  meta: {
    apiClient: {
      items,
      targetFolder
    }
  }
});

const uglyCopyItems = (items, targetFolder) => ({
  type: actions.UGLY_COPY_ITEMS,
  payload: {
    items,
    targetFolder
  },
  meta: {
    apiClient: {
      items,
      targetFolder
    }
  }
});

export const actionCreators = {
  retrieveResourceEdges,
  retrieveMoreResourceEdges,
  retrieveAllResourceEdges,
  receiveResourceEdges,
  receiveRelations,
  receivePagianationSize,
  setRelationStale,
  actionFailed,
  //
  mutateResourceEdge,
  mutateManyResourceEdges,
  addResourceEdge,
  removeResourceEdge,
  updateResourceEdge,
  uglyMoveItems,
  uglyCopyItems
};
