import { Account } from '../../../common/model/schema/resources/account';
import { Activity } from '../../../common/model/schema/resources/activities';
import { Approval } from '../../../common/model/schema/resources/approval';
import { Asset } from '../../../common/model/schema/resources/asset';
import { ChangeRequest } from '../../../common/model/schema/resources/change-request';
import { ContentTreeNode } from '../../../common/model/schema/resources/content-tree-node';
import { Contract } from '../../../common/model/schema/resources/contract';
import { ContractForm } from '../../../common/model/schema/resources/contract-form';
import { ContractKeyGroup } from '../../../common/model/schema/resources/contract-key-group';
import { ContractValue } from '../../../common/model/schema/resources/contract-value';
import { Comment } from '../../../common/model/schema/resources/comment';
import { ContractType } from '../../../common/model/schema/resources/contractType.ts';
import { Download } from '../../../common/model/schema/resources/download';
import { ExportBatch } from '../../../common/model/schema/resources/export-batch';
import { ExportTarget } from '../../../common/model/schema/resources/export-target';
import { ExportTargetDefault } from '../../../common/model/schema/resources/export-target-default';
import { ExportTargetType } from '../../../common/model/schema/resources/export-target-type';
import { FeatureFlag } from '../../../common/model/schema/resources/featureFlag';
import { Folder } from '../../../common/model/schema/resources/folder';
import { Invitation } from '../../../common/model/schema/resources/invitation';
import { Library } from '../../../common/model/schema/resources/library';
import { Membership } from '../../../common/model/schema/resources/membership';
import { MetadataProperty } from '../../../common/model/schema/resources/metadata-property';
import { MetadataValue } from '../../../common/model/schema/resources/metadata-value';
import { Oauth2 } from '../../../common/model/schema/resources/oauth2';
import { Oauth2Provider } from '../../../common/model/schema/resources/oauth2-provider';
import { Organization } from '../../../common/model/schema/resources/organization';
import { Privilege } from '../../../common/model/schema/resources/privilege';
import { Project } from '../../../common/model/schema/resources/project';
import { ProjectMember } from '../../../common/model/schema/resources/project-member';
import { ProjectTeamMembership } from '../../../common/model/schema/resources/project-team-membership';
import { ProjectTemplate } from '@cube3/common/model/schema/resources/project-template';
import { Properties } from '../../../common/model/schema/resources/properties';
import { Recipient } from '../../../common/model/schema/resources/recipient';
import { RenderBatch } from '../../../common/model/schema/resources/render-batch';
import { RenderPreset } from '../../../common/model/schema/resources/render-preset';
import { Role } from '../../../common/model/schema/resources/role';
import { SearchResult } from '../../../common/model/schema/resources/search-result';
import { Share } from '../../../common/model/schema/resources/share';
import { Tag } from '../../../common/model/schema/resources/tag';
import { Team } from '../../../common/model/schema/resources/team';
import { User } from '../../../common/model/schema/resources/user';
import { Validation } from '../../../common/model/schema/resources/validation';
import { Validator } from '../../../common/model/schema/resources/validator';
import { Workspace } from '../../../common/model/schema/resources/workspace';
import { WorkspacePreferences } from '../../../common/model/schema/resources/workspace-preference';
import cubeLogo from './assets/cube_logo.svg?url';
import cubeLogoNew from './assets/cube_logo_new.svg?url';
import { createGenericMethods } from './generic-methods';
import { MetadataForm } from '@cube3/common/model/schema/resources/metadata-category';
import { Favorite } from '@cube3/common/model/schema/resources/favorite';
import { Url } from '@cube3/common/model/schema/resources/url';
import { AuthService } from './authService';
import { TagCategory } from '@cube3/common/model/schema/resources/tag-category';
import { Snapshot } from '@cube3/common/model/schema/resources/snapshot';

const accountParser = (account) => {
  return {
    ...account,
    display_image: account.display_image
  };
};

const userParser = ({ email, ...user }) => {
  return {
    ...user,
    email_address: user.email_address || email
  };
};

const workspaceParser = (workspace) => {
  return {
    ...workspace,
    display_image:
      workspace.display_image ||
      [cubeLogo, cubeLogoNew][localStorage.getItem('new_logo') !== null ? 1 : 0]
  };
};

const invitationParser = (invitation) => {
  const { id, invitation_hash, ...rest } = invitation;
  return {
    id: id || invitation_hash,
    invitation_hash,
    ...rest
  };
};

const libraryParser = (library) => {
  const display_name = library['display-name'];
  const l = {
    ...library,
    display_name
  };
  delete l['display-name'];
  return l;
};

const contentTreeNodeParser = (node) => {
  const n = { ...node, type: 'content-tree-node' };
  return n;
};

const searchResultParser = (searchResult) => {
  return {
    ...searchResult,
    id: searchResult.uuid || searchResult.resource_id
  };
};

export const buildMapping = (authService: AuthService, endpoints) => {
  const {
    genericList,
    genericGet,
    genericPost,
    genericPut,
    genericPatch,
    genericDelete,
    genericAdd,
    genericRemove,
    genericReplace
  } = createGenericMethods(endpoints);

  const mapping = {
    /*
     *  SESSION, LOGIN, REGISTRATION and other non REST stuff
     */
    AUTH: {
      login: ({ creds }) => authService.login(creds),
      loginWithPasswordRequest: ({ creds }) =>
        authService.loginWithPasswordRequest(creds),
      logout: authService.logout,
      sso: ({ token }) => {
        authService.sso(token);
      },
      setWorkspaceId: (id) => {
        authService.config.workspaceId = id;
      },
      setShareToken: (id) => {
        authService.config.shareId = id;
      },
      setSharePassword: authService.setSharePassword,
      handleAuthErrors: (config) => authService.handleAuthErrors(config),
      clearStores: () => authService.clearStores(),
      registerAccount: authService.registerAccount,
      getLegacyToken: () => authService.getLegacyToken(),
      setIntegrationCookies: authService.setIntegrationCookies,
      setIOClientUrl: authService.setIoclientUrl,
      getAccessToken: authService.getAccessToken,
      request: authService.getAxios().request,
      getActiveSessions: authService.getActiveSessions,
      verify: authService.verify,
      magicLinkLogin: authService.magicLinkLogin,
      magicLinkActivate: authService.magicLinkActivate,
      requestShareAcccess: authService.requestShareAcccess,
      approveShareAccess: authService.approveShareAccess
    } as const,
    // methods passed to compose subapps
    COMPOSE: {
      request: (req) => {
        if (
          req?.url?.match(/^\//) ||
          (new URL(req?.url).host === window.location.host &&
            new URL(req?.url).protocol === window.location.protocol)
        ) {
          return authService.getAxios().request(req);
        }
      },
      handler: authService.callLegacyHandler
    } as const,
    /*
     *  INVITATIONS
     */
    invitation: {
      list: genericList<Invitation>('invitation', invitationParser),
      get: genericGet<Invitation>('invitation', invitationParser),
      post: genericPost<Invitation>('invitation', invitationParser),
      delete: genericDelete<Invitation>('invitation')

      // put: (id, body, options): APIResourceResponse<Invitation> => {
      //   return endpoints.invitation
      //     .custom({ method: 'PUT', url: '/invitations/', data: body })
      //     .then(res => res.data)
      //     .then(res => ({ ...res, type: 'invitiation' }))
      //     .then(invitationParser)
      //     .then(res => ({ [LEGACY_API_RESOURCE]: res }));
      // }
    },
    /*
     *  USERS
     */
    user: {
      list: genericList<User>('user', userParser),
      get: genericGet<User>('user', userParser),
      put: genericPut<User>('user', userParser)
    },
    /*
     *  ACCOUNTS
     */
    account: {
      list: genericList<Account>('account', accountParser),
      get: genericGet<Account>('account', accountParser),
      put: genericPut<Account>('account', accountParser),
      patch: genericPatch<Account>('account', accountParser),
      delete: genericDelete<Account>('account', accountParser),
      addRelated: genericAdd<Account>('account'),
      removeRelated: genericRemove<Account>('account')
    },
    /*
     *  ORGANIZATIONS
     */
    organization: {
      list: genericList<Organization>('organization'),
      get: genericGet<Organization>('organization')
    },
    /*
     *  WORKSPACES
     */
    workspace: {
      list: genericList<Workspace>('workspace', workspaceParser),
      get: genericGet<Workspace>('workspace', workspaceParser),
      addRelated: genericAdd<Workspace>('workspace'),
      patch: genericPatch<Workspace>('workspace', workspaceParser),
      removeRelated: genericRemove<Workspace>('workspace')
    },
    'workspace-preference': {
      get: genericGet<WorkspacePreferences>('workspace-preference'),
      patch: genericPatch<WorkspacePreferences>('workspace-preference')
    },
    /**
     *  CONTRACTS
     */
    contract: {
      list: genericList<Contract>('contract'),
      get: genericGet<Contract>('contract'),
      post: genericPost<Contract>('contract'),
      patch: genericPatch<Contract>('contract'),
      delete: genericDelete<Contract>('contract'),
      addRelated: genericAdd<Contract>('contract'),
      removeRelated: genericRemove<Contract>('contract')
    },
    'contract-type': {
      list: genericList<ContractType>('contract-type'),
      get: genericGet<ContractType>('contract-type')
    },
    'contract-key-group': {
      list: genericList<ContractKeyGroup>('contract-key-group'),
      get: genericGet<ContractKeyGroup>('contract-key-group')
    },
    'contract-value': {
      list: genericList<ContractValue>('contract-value'),
      get: genericGet<ContractValue>('contract-value'),
      patch: genericPatch<ContractValue>('contract-value'),
      post: genericPost<ContractValue>('contract-value'),
      delete: genericDelete<ContractValue>('contract-value')
    },
    'contract-form': {
      list: genericList<ContractForm>('contract-form'),
      get: genericGet<ContractForm>('contract-form')
    },
    /*
     *  PROJECTS
     */
    project: {
      list: genericList<Project>('project'),
      get: genericGet<Project>('project'),
      post: genericPost<ProjectMember>('project'),
      patch: genericPatch<Project>('project'),
      delete: genericDelete<Project>('project'),
      addRelated: genericAdd<Project>('project'),
      removeRelated: genericRemove<Project>('project')
    },
    'project-member': {
      list: genericList<ProjectMember>('project-member'),
      get: genericGet<ProjectMember>('project-member'),
      post: genericPost<ProjectMember>('project-member'),
      patch: genericPatch<ProjectMember>('project-member'),
      delete: genericDelete<ProjectMember>('project-member')
    },
    'project-template': {
      list: genericList<ProjectTemplate>('project-template'),
      get: genericGet<ProjectTemplate>('project-template'),
      post: genericPost<ProjectTemplate>('project-template'),
      delete: genericDelete<ProjectTemplate>('project-template'),
      patch: genericPatch<ProjectTemplate>('project-template')
    },
    'project-team-membership': {
      list: genericList<ProjectTeamMembership>('project-team-membership'),
      get: genericGet<ProjectTeamMembership>('project-team-membership'),
      post: genericPost<ProjectTeamMembership>('project-team-membership'),
      patch: genericPatch<ProjectTeamMembership>('project-team-membership'),
      delete: genericDelete<ProjectTeamMembership>('project-team-membership')
    },
    /*
     *  LIBRARIES
     */
    library: {
      list: genericList<Library>('library', libraryParser),
      get: genericGet<Library>('library', libraryParser)
    },

    'content-tree-node': {
      list: genericList<ContentTreeNode>(
        'content-tree-node',
        contentTreeNodeParser
      ),
      get: genericGet<ContentTreeNode>(
        'content-tree-node',
        contentTreeNodeParser
      ),
      post: genericPost<ContentTreeNode>(
        'content-tree-node',
        contentTreeNodeParser
      ),
      patch: genericPatch<ContentTreeNode>(
        'content-tree-node',
        contentTreeNodeParser
      ),
      delete: genericPatch<ContentTreeNode>(
        'content-tree-node',
        contentTreeNodeParser
      ),
      addRelated: genericAdd<ContentTreeNode>('content-tree-node'),
      removeRelated: genericRemove<ContentTreeNode>('content-tree-node'),
      /** use to delete/restore items in trash bin */
      replaceRelated: genericReplace<ContentTreeNode>('content-tree-node')
    },
    /*
     *  FOLDERS
     */
    folder: {
      list: genericList<Folder>('folder'),
      get: genericGet<Folder>('folder'),
      patch: genericPatch<Folder>('folder'),
      post: genericPost<Folder>('folder'),
      delete: genericDelete<Folder>('folder'),
      move: (edgeResource, by) => {
        return endpoints.folder.move(edgeResource, by.id, by.type);
      },
      copy: (edgeResource, by) => {
        return endpoints.folder.copy(edgeResource, by.id, by.type);
      },
      addRelated: genericAdd<Folder>('folder'),
      removeRelated: genericRemove<Folder>('folder')
    },
    /*
     *  ASSETS
     */
    asset: {
      list: genericList<Asset>('asset'),
      get: genericGet<Asset>('asset'),
      patch: genericPatch<Asset>('asset'),
      put: genericPut<Asset>('asset'),
      post: genericPost<Asset>('asset'),
      delete: genericDelete<Asset>('asset'),
      // TODO: remove
      // move: (edgeResource, by) => {
      //   return endpoints.asset.move(edgeResource, by.id, by.type, by.field);
      // },
      // copy: (edgeResource, by) => {
      //   return endpoints.asset.copy(edgeResource, by.id, by.type, by.field);
      // },
      addRelated: genericAdd<Asset>('asset'),
      removeRelated: genericRemove<Asset>('asset')
    },
    url: {
      get: genericGet<Url>('url'),
      post: genericPost<Url>('url'),
      patch: genericPatch<Url>('url')
    },
    /*
     *  VERSIONS
     */
    version: {},
    /*
     *  MEDIA
     */
    media: {},
    /*
     *  FILE
     */
    file: {},

    /*
     * Shares
     */
    share: {
      list: genericList<Share>('share'),
      get: genericGet<Share>('share'),
      patch: genericPatch<Share>('share'),
      post: genericPost<Share>('share'),
      delete: genericDelete<Share>('share')
    },
    'metadata-category': {
      list: genericList<MetadataForm>('metadata-category'),
      get: genericGet<MetadataForm>('metadata-category')
    },
    'metadata-value': {
      list: genericList<MetadataValue>('metadata-value'),
      get: genericGet<MetadataValue>('metadata-value'),
      patch: genericPatch<MetadataValue>('metadata-value'),
      post: genericPost<MetadataValue>('metadata-value'),
      delete: genericDelete<MetadataValue>('metadata-value')
    },
    'metadata-property': {
      list: genericList<MetadataProperty>('metadata-property'),
      get: genericGet<MetadataProperty>('metadata-property')
    },
    /*
     * ChangeRequest
     */
    'change-request': {
      list: genericList<ChangeRequest>('change-request'),
      get: genericGet<ChangeRequest>('change-request'),
      put: genericPut<ChangeRequest>('change-request'),
      post: genericPost<ChangeRequest>('change-request')
    },
    /*
     * Search
     */
    'search-result': {
      list: genericList<SearchResult>('search-result', searchResultParser),
      get: genericGet<SearchResult>('search-result', searchResultParser),
      put: genericPut<SearchResult>('search-result', searchResultParser),
      post: genericPost<SearchResult>('search-result', searchResultParser)
    },
    'meili-search-result': {
      list: genericList<SearchResult>(
        'meili-search-result',
        searchResultParser
      ),
      get: genericGet<SearchResult>('meili-search-result', searchResultParser),
      put: genericPut<SearchResult>('meili-search-result', searchResultParser),
      post: genericPost<SearchResult>('meili-search-result', searchResultParser)
    },
    recipient: {
      list: genericList<Recipient>('recipient')
    },
    membership: {
      list: genericList<Membership>('membership')
    },

    role: {
      list: genericList<Role>('role'),
      get: genericGet<Role>('role')
    },
    activity: {
      list: genericList<Activity>('activity'),
      get: genericGet<Activity>('activity')
    },
    'feature-flag': {
      list: genericList<FeatureFlag>('feature-flag')
    },
    download: {
      get: genericGet<Download>('download'),
      post: genericPost<Download>('download')
    },
    'render-preset': {
      list: genericList<RenderPreset>('render-preset'),
      post: genericPost<RenderPreset>('render-preset'),
      get: genericGet<RenderPreset>('render-preset')
    },
    snapshot: {
      post: genericPost<Snapshot>('snapshot'),
      list: genericList<Snapshot>('snapshot'),
      get: genericGet<Snapshot>('snapshot')
    },
    'render-batch': {
      get: genericGet<RenderBatch>('render-batch'),
      post: genericPost<RenderBatch>('render-batch')
    },
    privilege: {
      list: genericList<Privilege>('privilege')
    },
    tag: {
      list: genericList<Tag>('tag'),
      get: genericGet<Tag>('tag'),
      post: genericPost<Tag>('tag'),
      patch: genericPatch<Tag>('tag'),
      delete: genericDelete<Tag>('tag'),
      addRelated: genericAdd<Tag>('tag'),
      removeRelated: genericRemove<Tag>('tag')
    },
    'tag-category': {
      list: genericList<TagCategory>('tag-category'),
      get: genericGet<TagCategory>('tag-category'),
      post: genericPost<TagCategory>('tag-category'),
      patch: genericPatch<TagCategory>('tag-category'),
      delete: genericDelete<TagCategory>('tag-category')
    },
    properties: {
      get: genericGet<Properties>('properties'),
      list: genericList<Properties>('properties')
    },
    /**
     * exports
     */
    'export-target-type': {
      list: genericList<ExportTargetType>('export-target-type'),
      get: genericGet<ExportTargetType>('export-target-type')
    },
    'export-target': {
      list: genericList<ExportTarget>('export-target'),
      get: genericGet<ExportTarget>('export-target'),
      delete: genericDelete<ExportTarget>('export-target')
    },
    'export-target-default': {
      list: genericList<ExportTargetDefault>('export-target-default'),
      get: genericGet<ExportTargetDefault>('export-target-default'),
      post: genericPost<ExportTargetDefault>('export-target-default'),
      patch: genericPatch<ExportTargetDefault>('export-target-default'),
      delete: genericDelete<ExportTargetDefault>('export-target-default')
    },
    'export-batch': {
      list: genericList<ExportBatch>('export-batch'),
      get: genericGet<ExportBatch>('export-batch'),
      post: genericPost<ExportBatch>('export-batch')
    },
    validator: {
      list: genericList<Validator>('validator')
    },
    validation: {
      list: genericList<Validation>('validation'),
      post: genericPost<Validation>('validation')
    },
    /** */
    oauth2: {
      post: genericPost<Oauth2>('oauth2'),
      delete: genericPost<Oauth2>('oauth2')
    },
    'oauth2-provider': {
      list: (config, params, abortSignal) => {
        config.field = 'oauth2/providers';
        return genericList<Oauth2Provider>('oauth2-provider')(
          config,
          params,
          abortSignal
        );
      }
    },
    team: {
      list: genericList<Team>('team'),
      get: genericGet<Team>('team'),
      post: genericPost<Team>('team'),
      patch: genericPatch<Team>('team'),
      delete: genericDelete<Team>('team')
    },
    comment: {
      list: genericList<Comment>('comment'),
      get: genericGet<Comment>('comment'),
      post: genericPost<Comment>('comment'),
      patch: genericPatch<Comment>('comment'),
      delete: genericDelete<Comment>('comment')
    },
    approval: {
      list: genericList<Approval>('approval'),
      get: genericGet<Approval>('approval'),
      post: genericPost<Approval>('approval'),
      patch: genericPatch<Approval>('approval'),
      delete: genericDelete<Approval>('approval')
    },
    favorite: {
      list: genericList<Favorite>('favorite'),
      get: genericGet<Favorite>('favorite'),
      post: genericPost<Favorite>('favorite'),
      delete: genericDelete<Favorite>('favorite')
    }
  };

  Object.keys(mapping).forEach((k) => {
    mapping[k] = {
      ...mapping[k],
      canonicalPath: endpoints[k] && endpoints[k].config.canonicalPath
    };
  });

  return mapping;
};
