import { useButton, UseButtonParameters } from '@mui/base/useButton';
import React, { EventHandler, forwardRef } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { Icon } from '../Icon';
import { iconFiles } from '../Icon/files';
import { SizeVariants } from '../../../theme/themes';
import { useClassName } from '@cube3/common/utils/useClassName';
import { cx } from '@emotion/css';

/**
 * The Button component displays textual and visual content to indicate
 * an action that is triggered when the user clicks it.
 * The different colors and states exist to indicate a variety of conditions
 *  that a button can be used for.
 * The button component can also be used as an <b>icon button</b>, which is a
 * button that only displays an icon, the icon could be anyone from the icon archive.
 */

export interface ButtonProps
  extends React.PropsWithChildren,
    UseButtonParameters {
  /**  Click handler */
  onClick?: EventHandler<React.MouseEvent>;
  onMouseDown?: EventHandler<React.MouseEvent>;
  // Button label text or element. If unset `props.children` is used  (old discription)
  /**Label of the button */
  label?: string;
  /** Button size variant */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /**Button is disabled and the user cannot interact with it */
  disabled?: boolean;
  /** Node to use as left side icon */
  iconLeft?: keyof typeof iconFiles;
  /** Node to use as right side icon */
  iconRight?: keyof typeof iconFiles;
  /** Whether to add a dropdown style chevron icon */
  dropdown?: boolean;

  /**The background color of the button */
  background?: 'primary' | 'secondary' | 'critical';
  /**Styles that the icon can have */
  buttonStyle?: 'solid' | 'outline' | 'ghost'; //we don't use outline style anymore but we can keep it for now
  // /**The states that the button could be in. */
  stateOverride?: 'default' | 'hover' | 'active' | 'focus' | 'disabled';
  // /**Extra props that get applied to the root container */
  extraRootProps?: { [key: string]: any };
}

/**function to minimize the repetition of the linear-gradients */
/**for the hover state*/
const hoverLinearGradient = (newWord, theme) => {
  let hoverStateString = '';
  if (newWord === 'primary') {
    hoverStateString = `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), ${theme.color.background[newWord]};`;
  } else {
    hoverStateString = `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, ${theme.color.overlay.hovered} 0%, ${theme.color.overlay.hovered} 100%), ${theme.color.background[newWord]};`;
  }
  return hoverStateString;
};

/** for the active state*/
const activeLinearGradient = (newWord, theme) => {
  let activeStateString = '';
  if (newWord === 'primary') {
    activeStateString = `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.24) 100%), ${theme.color.background[newWord]};`;
  } else {
    activeStateString = `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, ${theme.color.overlay.pressed} 0%, ${theme.color.overlay.pressed} 100%), ${theme.color.background[newWord]};`;
  }

  return activeStateString;
};

const useCSS = makeCSS(({ theme }) => {
  const unit = parseInt(theme.spacing['1']);

  return {
    root: {
      cursor: 'pointer',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: theme.borderRadius.lg,
      fontFamily: theme.fontFamilies.body,
      fontWeight: theme.fontWeights.medium,
      fontStyle: 'normal',
      border: 'none',

      '&:focus': {
        outline: 'none',
        boxShadow: `0px 0px 0px 2px rgba(255, 212, 35, 0.12)`
      },

      /**states of the button*/
      '&.hover': {
        background: theme.color.overlay.hovered
      },
      '&.focus': {
        boxShadow: ` 0px 0px 0px 2px rgba(255, 212, 35, 0.12)`
      },

      '&.active': {
        background: theme.color.overlay.pressed
      },

      /**needs to have the other states disabled  */
      '&.disabled': {
        cursor: 'default',
        boxShadow: 'none',
        opacity: 0.4
      }
    },

    'size-sm': {
      minWidth: unit * 8,
      height: unit * 8,
      fontSize: theme.fontSize[0],
      paddingLeft: unit * 2,
      paddingRight: unit * 2
    },
    /**extra padding between for the label */
    'space-sm': {
      paddingLeft: theme.spacing[1],
      paddingRight: theme.spacing[1]
    },
    space: {
      paddingLeft: theme.spacing[2],
      paddingRight: theme.spacing[2]
    },

    'size-md': {
      minWidth: unit * 10,
      height: unit * 10,
      fontSize: theme.fontSize[1],
      paddingLeft: unit * 2,
      paddingRight: unit * 2
    },

    'size-lg': {
      minWidth: unit * 12,
      height: unit * 12,
      paddingLeft: unit * 3,
      paddingRight: unit * 3,
      fontSize: theme.fontSize[3]
    },

    iconLeft: {
      marginRight: unit * 1
    },
    iconRight: {
      marginLeft: unit * 1
    },
    iconButton: { margin: 0 },

    /**colors */
    primary: {
      color: theme.color.text.light['01'],
      borderColor: theme.color.background.primary,
      /**when hover */
      '&:hover': {
        background: hoverLinearGradient('primary', theme)
      },
      '&:active': {
        background: activeLinearGradient('primary', theme)
      }
    },
    secondary: {
      color: theme.color.text.dark['01'],
      /**when hover */
      '&:hover': {
        background: hoverLinearGradient('secondary', theme)
      },
      '&:active': {
        background: activeLinearGradient('secondary', theme)
      }
    },
    critical: {
      color: theme.color.text.dark['01'],
      /**when hover */
      '&:hover': {
        background: hoverLinearGradient('critical', theme)
      },
      '&:active': {
        background: activeLinearGradient('critical', theme)
      }
    },
    /*style -> 'solid' 'outline' 'ghost' */
    'solid-primary': {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.primary};`,
      '&.hover': {
        background: hoverLinearGradient('primary', theme)
      },
      '&.active': {
        background: activeLinearGradient('primary', theme)
      },
      '&.disabled': {
        color: theme.color.text.dark['01'],
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`,
        '&:hover': {
          background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`
        }
      }
    },
    'outline-primary': {
      background: 'none',
      color: theme.color.foreground.primary,
      border: `1px solid ${theme.color.foreground.primary}`,
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        border: `1px solid ${theme.color.background.secondary}`,
        '&:hover': {
          background: 'none'
        }
      }
    },
    'ghost-primary': {
      background: 'none',
      color: theme.color.foreground.primary,
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        '&:hover': {
          background: 'none'
        }
      }
    },
    'solid-secondary': {
      color: theme.color.text.dark['01'],
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary};`,
      '&.hover': {
        background: hoverLinearGradient('secondary', theme)
      },
      '&.active': {
        background: activeLinearGradient('secondary', theme)
      },
      '&.disabled': {
        color: theme.color.text.dark['01'],
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`,
        '&:hover': {
          background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`
        }
      }
    },
    'outline-secondary': {
      background: 'none',
      color: theme.color.text['03'],
      border: `1px solid ${theme.color.foreground.secondary}`,
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        border: `1px solid ${theme.color.background.secondary}`,
        '&:hover': {
          background: 'none'
        }
      }
    },
    'ghost-secondary': {
      background: 'none',
      color: theme.color.text['03'],
      '&:hover': {
        background: theme.color.overlay.hovered,
        color: theme.color.text['01']
      },
      '&:active, &.active': {
        background: theme.color.overlay.pressed,
        color: theme.color.text['01']
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        '&:hover': {
          background: 'none'
        }
      }
    },
    'solid-critical': {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.critical};`,
      '&.hover': {
        background: hoverLinearGradient('critical', theme)
      },
      '&.active': {
        background: activeLinearGradient('critical', theme)
      },
      '&.disabled': {
        color: theme.color.text.dark['01'],
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`,
        '&:hover': {
          background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`
        }
      }
    },
    'outline-critical': {
      background: 'none',
      color: theme.color.foreground.critical,
      border: `1px solid ${theme.color.foreground.critical}`,
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        border: `1px solid ${theme.color.background.secondary}`,
        '&:hover': {
          background: 'none'
        }
      }
    },
    'ghost-critical': {
      background: 'none',
      color: theme.color.foreground.critical,
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:active': {
        background: theme.color.overlay.pressed
      },
      '&.disabled': {
        color: theme.color.text['03'],
        background: 'none',
        '&:hover': {
          background: 'none'
        }
      }
    },
    /*disable state styles */
    'solid-disable': {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`,
      '&:hover': {
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.color.background.secondary} ;`
      }
    },
    'outline-disable': {
      background: 'none',
      borderColor: `1px solid ${theme.color.background.secondary}`,
      '&:hover': {
        background: 'none'
      }
    },
    'ghost-disable': {
      background: 'none',
      '&:hover': {
        background: 'none'
      }
    }
  };
});

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function (
  props: ButtonProps,
  ref
) {
  let disableFinalStyle = '';
  /**set the style of the disable state according to its buttonStyle*/
  if (props.disabled || props.stateOverride === 'disabled') {
    disableFinalStyle = `${props.buttonStyle}-disabled}`;
  }

  const {
    children,
    onClick,
    label = children,
    background = 'primary',
    disabled,
    iconLeft,
    iconRight,
    dropdown,
    size = SizeVariants.md,
    stateOverride = 'default',
    buttonStyle = 'solid',
    extraRootProps = {}
  } = props;
  const disableStyle = disableFinalStyle;

  /**go throw the diffferent styles (solid, outline, ghost)
   * according to the background color of the button
   */

  const buttonFinalStyle = `${buttonStyle}-${background}`;

  const ub = useButton({
    ...props,
    rootRef: ref
  });

  const classes = useCSS();

  //the icon colors
  let iconColor: 'default' | 'light' | 'dark';
  let customIconColor;

  if (background === 'primary') {
    if (buttonStyle === 'ghost' || buttonStyle === 'outline') {
      customIconColor = (theme) => theme.color.background.primary;
    } else {
      iconColor = 'light';
    }
  }
  if (background === 'secondary') {
    if (buttonStyle === 'ghost' || buttonStyle === 'outline') {
      customIconColor = (theme) => theme.color.background.secondary;
    } else {
      iconColor = 'dark';
    }
  }
  if (background === 'critical') {
    if (buttonStyle === 'ghost' || buttonStyle === 'outline') {
      customIconColor = (theme) => theme.color.background.negative;
    } else {
      iconColor = 'dark';
    }
  }

  const rp = ub.getRootProps({ onClick });

  const handleKeyDown = (event) => {
    //when it's disabled it shouldn't be able to have click events
    if (disabled) {
      console.log('checkBox disabled');
    } else {
      //when ENTER and SPACE are pushed
      //keycode for enter is 13
      //keycode for space is 32
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault();
        rp.onClick(event);
      }
    }
  };

  return (
    <div {...extraRootProps} style={{ display: 'inline-block' }}>
      {/*when the button is completely empty is should not appear */}
      {label != '' ||
      iconLeft != null ||
      iconRight != null ||
      dropdown === true ? (
        <button
          onKeyDown={handleKeyDown}
          {...rp}
          className={useClassName(
            disabled && 'disabled',
            classes.root,
            classes[`size-${size}`],
            classes[background],
            classes[buttonStyle],
            classes[buttonFinalStyle],
            classes[disableStyle],
            stateOverride
          )}
        >
          {iconLeft && (
            <div
              className={cx(
                label != '' && label != undefined
                  ? classes.iconLeft
                  : classes.iconButton
              )}
            >
              <Icon
                icon={iconLeft}
                color={iconColor}
                customColor={customIconColor}
                size={size}
              />
            </div>
          )}
          {label != '' && label != undefined ? (
            <div>
              {size === SizeVariants.sm ? (
                <div className={classes['space-sm']}>{label}</div>
              ) : (
                <div className={classes.space}>{label}</div>
              )}
            </div>
          ) : (
            ''
          )}
          {iconRight && (
            <div
              className={cx(
                label != '' && label != undefined
                  ? classes.iconRight
                  : classes.iconButton
              )}
            >
              <Icon
                icon={iconRight}
                color={iconColor}
                customColor={customIconColor}
                size={size}
              />
            </div>
          )}
          {dropdown && (
            <div>
              <Icon
                icon="chevron_down"
                color={iconColor}
                customColor={customIconColor}
                size={size}
              />
            </div>
          )}
        </button>
      ) : null}
    </div>
  );
});

export { Button };

export default Button;
