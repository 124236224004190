import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';
import { Divider } from '../../../Surface/Divider';

// forms and textfields
import LinkSettingsContent from '../../shareLink/LinkSettingsContent';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButtonMargin: {
      marginLeft: '8px'
    },
    content: {
      minHeight: '70vh'
    },
    container: {
      padding: `0px 0px ${theme.customSpacing.padding[24]} 0px`
    },
    subTitle: {
      marginBottom: theme.customSpacing.margin[16]
    },
    optionsText: {
      paddingBottom: 12
    },
    cancelButton: {
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      marginRight: '10px',
      border: `${theme.customPalette.primary.lineWhiteLowEmphasis} 1px solid`,
      '&:hover': {
        backgroundColor: 'initial'
      }
    },
    submitButton: {
      backgroundColor: theme.customPalette.secondary.light,
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      '&:hover': {
        backgroundColor: theme.customPalette.secondary.light
      }
    },
    divider: {
      marginBottom: '20px'
    }
  })
);

interface Properties {
  loading: boolean;

  // events
  onCloseEvent: () => void;
  onClickCreateShare: () => void;

  titleFieldComponent: JSX.Element;

  // render props
  renderPropsEmailBox: () => JSX.Element;
  renderPropsLinkSettings: () => JSX.Element;

  submitDisabled: boolean;
}

const FileRequestNewModalUIC: React.FC<Properties> = (props) => {
  const {
    loading,
    onCloseEvent,
    onClickCreateShare,
    submitDisabled,
    renderPropsEmailBox,
    renderPropsLinkSettings,
    titleFieldComponent
  } = props;

  const classes = useStyles();

  useGlobalSubmit(onClickCreateShare, submitDisabled || loading);

  return (
    <ModalMenuUI
      loading={loading}
      title={'New File Request'}
      multiplyContentSpace={11}
      onCloseEvent={() => onCloseEvent()}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant="ghost2"
            onClick={() => onCloseEvent()}
          />
          <Button
            text={'Create file request'}
            classes={{ root: classes.footerButtonMargin }}
            colorVariant={'filled1'}
            onClick={onClickCreateShare}
            disabled={submitDisabled}
          />
        </>
      }
    >
      {/* Title */}
      <div className={classes.container}>
        <Typography
          component="span"
          typographyStyle={'heading3'}
          color={'contrast1'}
          className={classes.subTitle}
        >
          Title
        </Typography>

        <Typography
          component="span"
          typographyStyle={'body2'}
          color="contrast2"
          className={classes.subTitle}
        >
          What files are you requesting?
        </Typography>

        {titleFieldComponent}
      </div>

      <Divider className={classes.divider} />

      <div className={classes.container}>
        <Typography
          component="span"
          typographyStyle={'heading3'}
          color="contrast2"
          className={classes.subTitle}
        >
          Send links to
        </Typography>

        {renderPropsEmailBox()}
      </div>

      <Divider />

      <LinkSettingsContent containerStyles={classes.container}>
        <Typography
          component="span"
          typographyStyle={'heading3'}
          color="contrast2"
          className={classes.subTitle}
        >
          Options
        </Typography>
        {renderPropsLinkSettings()}
      </LinkSettingsContent>
    </ModalMenuUI>
  );
};

/**
 * @summary Modal with the ability to specify a configuration for the new `File Request`
 * @author Simon
 */
const FileRequestNewModalUI = FileRequestNewModalUIC;
export { FileRequestNewModalUI };
